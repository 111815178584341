import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Typography from "../../components/typography"
import Container from "../../components/container"
import Button from "../../components/button"

const Certificate = styled.img`
  width: 100%;
  margin: 3rem 0;
  object-fit: contain;
  object-position: center;
`

const Transanalsurgery = props => (
  <Layout {...props}>
    <SEO
      title="Cirurgia transanal minimamente invasiva"
      description="Microcirurgia transanal endoscópica (TEM) pela plataforma Sils Port (TAMIS - transanal minimal invasive surgery) apresentado no 66º Congresso Brasileiro de Coloproctologia"
    />
    <Container>
      <header style={{ padding: "2rem 0" }}>
        <Typography variant="h5" component="h2" color="primary">
          Microcirurgia transanal endoscópica (TEM) pela plataforma Sils Port
          (TAMIS - transanal minimal invasive surgery)
        </Typography>
        <Typography variant="h1" color="secondary">
          Ressecção de neoplasia do reto médio ocupando 3/4 da luz intestinal por
          TAMIS (Cirurgia transanal minimamente invasiva)
        </Typography>
      </header>
      <Typography variant="bodyLarge" color="secondary">
        <Button
          htmlType="link"
          color="link"
          to="/equipe/dr_cristiano_denoni_freitas"
        >
          CRISTIANO DENONI FREITAS
        </Button>
        {` (IMPERIAL HOSPITAL DE CARIDADE);`}
        <br />
        <Button
          htmlType="link"
          color="link"
          to="/equipe/dr_eduardo_miguel_schmidt"
        >
          EDUARDO MIGUEL SCHMIDT
        </Button>
        {` (IMPERIAL HOSPITAL DE CARIDADE);`}
        <br />
        <Button
          htmlType="link"
          color="link"
          to="/equipe/dr_mauricio_mendes_albuquerque"
        >
          MAURÍCIO MENDES DE ALBUQUERQUE
        </Button>
        {` (IMPERIAL HOSPITAL DE CARIDADE);`}
        <br />
        <Button htmlType="link" color="link" to="/equipe/dr_joao_paulo_farias">
          JOÃO PAULO FARIAS
        </Button>
        {` (IMPERIAL HOSPITAL DE CARIDADE);`}
        <br />
        RODRIGO SAMWAYS GUZZI (IMPERIAL HOSPITAL DE CARIDADE);
        <br />
        GIANFRANCO LUIGI COLOMBELI (MACRO E MICRO LABORATÓRIO DE ANATOMIA
        PATOLÓGICA);
        <br />
        MAURÍCIO SPEROTTO CECCON (IMPERIAL HOSPITAL DE CARIDADE)
      </Typography>
      <Typography variant="bodyLarge" color="secondary">
        <strong>Tipo: </strong>
        <span>Vídeo Livre</span>
      </Typography>
      <Typography variant="bodyLarge" color="secondary">
        <strong>Área: </strong>
        <span>Câncer do Cólon/Reto/Anus</span>
      </Typography>
      <Typography variant="bodyLarge" color="secondary">
        <strong>Introdução: </strong>
        <span>
          Introduzido por Atallah et al em 2009, o TAMIS (cirurgia transanal
          minimamente invasiva), consiste na utilização de dispositivos de
          acesso cirúrgico por porta única como plataforma de acesso transanal
          para a excisão em bloco de lesões neoplásicas do reto médio e
          superior. Em casos bem selecionados (cT1), proporciona boas taxas de
          controle local, baixas taxas de recidiva, preservação esfincteriana e
          evitando muitas vezes uma retossigmoidectomia com anastomoses baixas e
          suas consequências.
        </span>
      </Typography>
      <Typography variant="bodyLarge" color="secondary">
        <strong>Descrição do caso: </strong>
        <span>
          Paciente feminina 61 anos, diabética. Assintomática. Colonoscopia:
          lesão plana com aspecto adenomatoso, ocupando 3/4 da luz intestinal na
          parede posterior do reto médio. Biópsia a colonoscopia: adenoma viloso
          com displasia de alto grau.
        </span>
      </Typography>
      <Typography variant="bodyLarge" color="secondary">
        <strong>Discussão: </strong>
        <span>
          O vídeo demonstra a ressecção em bloco da lesão com parede total por
          TAMIS, seguido do fechamento do defeito com sutura contínua com fio
          absorvível com dispositivo de fechamento com micro âncoras. A paciente
          foi de alta no primeiro dia de pós-operatório sem intercorrências.
          Anátomo patológico demonstrou: adenocarcinoma tubular bem diferenciado
          em adenoma túbulo viloso com invasão da submucosa nível SM1 (pT1Nx). O
          seguimento colonoscópico não demonstrou até o momento recidiva local.
        </span>
      </Typography>
      <Typography variant="bodyLarge" color="secondary">
        <strong>Conclusão: </strong>
        <span>
          TAMIS uma técnica cirúrgica segura e eficaz com bons resultados
          oncológicos na abordagem de lesões do terço médio e superior do recto
          ocupando até 3/4 da luz do órgão.
        </span>
      </Typography>
      <Typography variant="bodyLarge" color="secondary">
        <strong>Palavras-Chave: </strong>
        <span>TAMIS, TEM, Microcirurgia transanal endoscópica</span>
      </Typography>
      <Certificate src="/certificado.png" />
    </Container>
  </Layout>
)

export default Transanalsurgery
